<template>
  <div>
    <label :for="uid" class="block text-sm font-medium leading-6 text-gray-900">{{ label }}</label>
    <div class="mt-1">
      <textarea
        :id="uid"
        ref="textarea"
        v-model="input"
        :placeholder="placeholder"
        rows="3"
        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
      />
    </div>
  </div>
</template>

<script setup>
import { useTextareaAutosize } from '@vueuse/core';
import { useId } from 'vue';

defineProps({
  label: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: null
  }
});

const uid = useId();

const model = defineModel({
  type: [ String, Number ],
  default: null
});

const { textarea, input } = useTextareaAutosize({ input: model, styleProp: 'minHeight' });
</script>
