<template>
  <page-header title="Program" :identifier="programId">
    <template v-if="isLoaded">
      <button v-if="programId" class="btn-outline-primary ml-auto h-10 mr-2" @click="toggleShowHideProgram">
        <template v-if="!program.deletedAt">
          <EyeSlashIcon class="size-5 -ml-1 mr-2" />
          Hide
        </template>
        <template v-else>
          <EyeIcon class="size-5 -ml-1 mr-2" />
          Show
        </template>
      </button>
      <button v-if="programId" class="btn-outline-primary ml-auto h-10 mr-2" @click="toggleEnableDisableProgram">
        <template v-if="program.isEnabled">
          <LockClosedIcon class="size-5 -ml-1 mr-2" />
          Disable
        </template>
        <template v-else>
          <LockOpenIcon class="size-5 -ml-1 mr-2" />
          Enable
        </template>
      </button>
      <button class="btn-primary ml-auto h-10" :class="{'disabled': isSaving || !isDataChanged}" @click="save">
        <CircleStackIcon class="size-5 -ml-1 mr-2" />
        Save
      </button>
    </template>
  </page-header>

  <pre v-if="false">
    {{ JSON.stringify(program, null, 2) }}
  </pre>

  <program-form v-if="isLoaded" ref="programFormRef" :program="program" @save="save" />

  <div v-else>
    Loading...
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onBeforeUnmount, toRaw } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import PageHeader from '../components/PageHeader.vue';
import ProgramForm from '../components/ProgramForm.vue';
import {
  CircleStackIcon,
  EyeIcon,
  EyeSlashIcon,
  LockOpenIcon,
  LockClosedIcon
} from '@heroicons/vue/24/outline';
import { useFetch } from '@vueuse/core';
import { apiDomain } from '../composables/useConstants';
import { Modal } from '../composables/useModal';
import { useToast } from 'vue-toastification';
import hashSum from 'hash-sum';

const props = defineProps({
  programId: {
    type: String,
    default: null
  }
});

const toast = useToast();
const programFormRef = ref(null);
const isLoaded = ref(false);
const isSaving = ref(false);
const program = ref({});
const programHashSum = ref(null);

const isDataChanged = computed(() => programHashSum.value !== hashSum(program.value));

if (props.programId) {
  load();
} else {
  isLoaded.value = true;
}

async function load() {
  const { data } = await useFetch(`${apiDomain}/api/program/${props.programId}`).get().json();
  programHashSum.value = hashSum(data.value);
  program.value = data.value;
  isLoaded.value = true;
}

async function save() {
  isSaving.value = true;

  programFormRef.value.onValidate();
  if (programFormRef.value.getResult().$invalid) {
    return;
  }

  const { data, error } = await useFetch(`${apiDomain}/api/program/`).post(toRaw(program.value)).json();

  isSaving.value = false;

  if (error.value) {
    toast.error(error.value);
  }

  if (data.value) {
    programHashSum.value = hashSum(data.value);
    program.value = data.value;
    toast.success('Program saved successfully');
  }
}

async function toggleShowHideProgram() {
  const direction = !program.value.deletedAt ? 'hide' : 'show';
  const { data } = await useFetch(`${apiDomain}/api/program/${direction}/${program.value.id}`).get().json();

  if (data.value?.success) {
    load();
    toast.success(`Program ${direction === 'show' ? direction : 'hidden'} successfully`);
    return;
  }

  toast.error('Error showing program');
}

async function toggleEnableDisableProgram() {
  const direction = program.value.isEnabled ? 'disable' : 'enable';
  const { data } = await useFetch(`${apiDomain}/api/program/${direction}/${program.value.id}`).get().json();

  if (data.value?.success) {
    load();
    toast.success(`Program ${direction}d successfully`);
    return;
  }

  toast.error('Error enabling program');
}

function handleBeforeUnload(e) {
  if (!isDataChanged.value || !Object.keys(program.value).length) {
    return;
  }
  e.preventDefault();
  e.returnValue = '';
}

onBeforeRouteLeave(async (to, from, next) => {
  if (!isDataChanged.value || !Object.keys(program.value).length) {
    return next();
  }

  const confirm = await Modal.confirm({
    title: 'Unsaved changes',
    body: `You have unsaved changes. <br/> If you leave this page, your changes will be lost. <br/> Are you sure you want to exit without saving?`,
    okButton: 'Leave',
    iconColor: 'primary'
  });

  return confirm ? next() : next(false);
});

onMounted(() => {
  window.addEventListener('beforeunload', handleBeforeUnload);
});

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
});
</script>
