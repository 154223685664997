import { ref } from 'vue';
import { apiDomain } from './useConstants';

const stats = ref(null);
const isRequested = ref(false);

async function getStatsAsync() {
  if (isRequested.value) {
    return stats;
  }

  isRequested.value = true;

  try {
    const [ publisherResponse, developerResponse, categoryResponse ] = await Promise.all([
      fetch(`${apiDomain}/api/publisher/`),
      fetch(`${apiDomain}/api/developer/`),
      fetch(`${apiDomain}/api/category/`)
    ]);

    const json = {};

    if (publisherResponse.status === 200) {
      json.publishers = await publisherResponse.json();
    }

    if (developerResponse.status === 200) {
      json.developers = await developerResponse.json();
    }

    if (categoryResponse.status === 200) {
      json.categories = await categoryResponse.json();
    }

    stats.value = { ...stats.value, ...json };

  } catch (error) {
    console.error('Error getting Stats', error);
  }

  return stats;
}

function getStats() {
  if (!isRequested.value) {
    getStatsAsync();
  }

  return stats;
}

async function reloadStats() {
  isRequested.value = false;
  return await getStatsAsync();
}

export {
  getStats,
  getStatsAsync,
  reloadStats
};
