<template>
  <sidebar-layout>
    <template #default>
      <router-view :key="$route.fullPath" />
    </template>

    <template #navigation>
      <sidebar-navigation :navigation="navigation" :links="links" />
    </template>

    <template #menu>
      <dropdown-user :user="user" :items="dropdownNavigation" @clicked="onClick" />
    </template>
  </sidebar-layout>
</template>

<script setup>
import { getUser } from '../composables/useAuth';
import SidebarLayout from '../templates/SidebarLayout.vue';
import SidebarNavigation from '../components/SidebarNavigation.vue';
import DropdownUser from '../components/DropdownUser.vue';
import { useRouter } from 'vue-router';

import {
  FolderIcon,
  HomeIcon,
  UserCircleIcon,
  CogIcon,
  PowerIcon,
  UsersIcon
} from '@heroicons/vue/24/outline';

const router = useRouter();
const user = getUser();

const navigation = [
  { name: 'Dashboard', to: '/dashboard', icon: HomeIcon },
  { name: 'Programs', to: '/programs', icon: FolderIcon },
  { name: 'Users', to: '/users', icon: UsersIcon }
];

const links = [
  { id: 1, name: 'Swagger admin API', href: '/docs/', initial: 'S' },
  { id: 2, name: 'Heroicons', href: 'https://heroicons.com/', initial: 'H' },
  { id: 3, name: 'Tailwind', href: 'https://tailwindcss.com/', initial: 'T' }
];

const dropdownNavigation = [
  [
    { name: 'Your Profile', icon: UserCircleIcon },
    { name: 'Settings', icon: CogIcon }
  ],
  [
    { name: 'Sign out', icon: PowerIcon, to: '/logout' }
  ]
];

function onClick(item) {
  if (item.to) {
    router.push(item.to);
    return;
  }

  console.log(item);
}
</script>
