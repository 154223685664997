<template>
  <div>
    <label :for="uid" class="block text-sm font-medium leading-6 text-gray-900">{{ label }}</label>
    <label class="relative inline-flex cursor-pointer items-center">
      <input :id="`switch_${uid}`" v-model="model" type="checkbox" class="peer sr-only">
      <label for="`switch_${uid}`" class="hidden" />
      <div class="peer h-6 w-11 rounded-full border bg-slate-200 after:absolute after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-green-300" />
    </label>
  </div>
</template>

<script setup>
import { useId } from 'vue';

defineProps({
  label: {
    type: String,
    default: null
  }
});

const model = defineModel({
  type: Boolean,
  default: false
});

const uid = useId();
</script>
