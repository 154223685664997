<template>
  <form class="flex flex-col" @submit.prevent="onSave">
    <form-input v-model="nameValue" :label="data.label" :placeholder="data.placeholder" class="lg:w-[300px] sm:w-full" />
    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <button
        type="submit"
        class="btn-primary text-white"
        :class="data.iconColor ? `border-${data.iconColor}-800 bg-${data.iconColor}-600 hover:bg-${data.iconColor}-800 focus:ring-${data.iconColor}-300` : ''"
      >
        Save
      </button>
      <button
        type="button"
        class="btn-outline-primary mr-2"
        @click="emit('close')"
      >
        Close
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref } from 'vue';
import FormInput from './FormInput.vue';
import { useToast } from 'vue-toastification';
import { apiDomain } from '../composables/useConstants';
import { useFetch } from '@vueuse/core';

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
});

const emit = defineEmits([ 'close' ]);

const nameValue = ref('');

const toast = useToast();

async function onSave() {
  const path = props.data.entity;
  const { data } = await useFetch(
    `${apiDomain}/api/${path}/`,
    {
      onFetchError: ctx => {
        if (ctx.response.ok) {
          return;
        }

        toast.error(ctx.data.error);
        emit('close', false);
      }
    }
  ).post(
    { name: nameValue.value }
  ).json();

  if (data.value) {
    toast.success(`New item of ${props.data.entity} saved successfully`);
    emit('close', data.value.id);
  }
}
</script>
