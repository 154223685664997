<template>
  <fieldset class="relative">
    <legend class="text-sm font-medium leading-6 text-gray-900">{{ label }}</legend>
    <p v-if="placeholder" class="mt-1 text-sm leading-6 text-gray-600">{{ placeholder }}</p>
    <div :class="vertical ? 'mt-6 space-y-6' : 'mt-1 flex items-center gap-x-10'">
      <div v-for="item in items" :key="item.value" class="flex items-center gap-x-3">
        <input
          :id="`${uid}-${item.value}`"
          v-model="model"
          :value="item.value"
          :name="`${uid}-radio-group`"
          type="radio"
          class="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600"
        >
        <label :for="`${uid}-${item.value}`" class="block text-sm font-medium leading-6 text-gray-900">{{ item.name }}</label>
      </div>
    </div>
    <div v-show="errorMsg" class="absolute text-xs text-red-500 mt-0.5">{{ errorMsg }}</div>
  </fieldset>
</template>

<script setup>
import { useId } from 'vue';

defineProps({
  vertical: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: null
  },
  items: {
    type: Array,
    default: () => []
  },
  errorMsg: {
    type: String,
    default: ''
  }
});

const uid = useId();

const model = defineModel({
  type: [ String, Number ],
  default: null
});
</script>
