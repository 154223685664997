<template>
  <Menu as="div" class="relative">
    <Float
      :placement="placement"
      :auto-placement="autoPlacement"
      enter="transition ease-out duration-100"
      enter-from="transform opacity-0 scale-95"
      enter-to="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leave-from="transform opacity-100 scale-100"
      leave-to="transform opacity-0 scale-95"
    >
      <MenuButton class="w-full flex items-center">
        <slot />
      </MenuButton>

      <MenuItems v-if="items.length" class="divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none mt-2 ml-2">
        <div v-for="(itemGroup, key) in items" :key="key" class="py-1">
          <MenuItem v-for="item in itemGroup" :key="item.name" v-slot="{ active }">
            <div
              class="group flex items-center px-3 py-2 text-sm cursor-pointer gap-x-2"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : null,
                isSelected(item) ? 'pointer-events-none text-gray-900' : 'text-gray-700'
              ]"
              @click="model = item"
            >
              <div v-if="withIcons" class="shrink-0">
                <component :is="item.icon || 'div'" class="size-5" />
              </div>
              <div class="flex-1 mr-4 text-nowrap">
                {{ item.name }}
              </div>
              <div class="shrink-0 w-5 flex items-center">
                <CheckIcon v-if="isSelected(item)" class="size-4" />
              </div>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </Float>
  </Menu>
</template>

<script setup>
import { computed } from 'vue';
import { CheckIcon } from '@heroicons/vue/24/outline';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from '@headlessui/vue';
import { Float } from '@headlessui-float/vue';

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  placement: {
    type: String,
    default: null
  },
  autoPlacement: {
    type: Boolean,
    default: true
  }
});

const model = defineModel({ type: Object, default: null });

const withIcons = computed(() => props.items.some(itemGroup => itemGroup.some(item => item.icon)));

function isSelected(item) {
  return model.value?.value !== undefined && model.value?.value === item?.value;
}
</script>
